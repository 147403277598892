import { Center, Spinner, Text, VStack, useToast } from '@chakra-ui/react';
import CartContext from 'context/CartProvider';
import { navigate } from 'gatsby';
import { useParams } from 'hooks/useParams';
import React, { useContext, useEffect } from 'react';

const CartIndexPage = () => {
  const token = useParams('t');
  const toast = useToast()

  const cartCtx = useContext(CartContext);

  useEffect(() => {

    const pageInit = async () =>{

      try {
        
          await cartCtx.fetchCart(token);
          navigate('/confirm-order')
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch cart. Redirecting to home page.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right"
        })
        navigate('/')
      }
    }
    if (token) pageInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Center h={'100dvh'}>
      <VStack gap={6}>
        <Spinner size={'xl'} color="teal.500" />
        <Text fontWeight={500}>Fetching your cart. Please wait...</Text>
      </VStack>
    </Center>
  );
};

export default CartIndexPage;
